import React from 'react';
import db from "../../stores/common-store";
import { observer } from "mobx-react";

 
   
const EmptyCart = ({ emptyCart }) => {

  if(!emptyCart)
  {
    emptyCart={
      buttonUrl:"/shop",
      buttonText:"Shop Now"
    }
  }  
   
  return (
    <div id="EmptyCart" >
      <div className="ajaxcart-empty">
        <h5 className="ajaxcart-empty__title">Your cart is currently empty.</h5>
        <p className="ajaxcart-empty__description">
          Fill your cart with some amazing products.
        </p>  
        <a
          href={emptyCart.buttonUrl}
          className="btn c-btn--full"
        >
        {emptyCart.buttonText}
        </a>
      </div>
    </div> 
  );
};
export default observer(EmptyCart);
