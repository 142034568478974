import React, { useState } from 'react';
import 'twin.macro';
import store from '../../../../stores/common-store';
import styled from 'styled-components';
import 'lodash';

const StyledDiv = styled.div`
  .cart_image {
  }
  .heading {
    align-self: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 0.8rem;
    line-height: 1.15rem;
    margin: 0px 0px 0.575rem;
    color: rgb(113, 113, 113);
  }
  .add_button {
    background: rgba(253, 109, 15, 0.68);
    border: 1px solid black;
    color: black;
    font-size: 12px;
    line-height: 1.42;
    font-weight: 900;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
  }
  .price {
    font-size: 0.875rem;
  }
  .price_off {
    color: red;
    font-size: 10px;
    line-height: 2;
    padding-left: 5px;
  }
`;
export default function TrendingProduct({img_src, heading, price, priceOff, trendingVariantId}) {
    const [adding, setadding] = useState(false);

    return (
        <StyledDiv tw="bg-white rounded-sm flex justify-center flex-col items-center mx-2 w-1/2">
        <img tw="h-20" src={img_src} alt="" />
        <p tw="text-center" className="heading">
          {heading}
        </p>
        <div tw="flex flex-row -mt-2 ">
          <p tw="-mt-2 text-center text-black" className="price">
            {price}
          </p>
          <p tw="-mt-2 text-center" className="price_off">
            {priceOff}
          </p>
        </div>
        <div
          tw="px-8 py-2 mb-2"
          className={`add_button ${adding ? 'btn--loading' : ''}`}
          onClick={async () => {
            let variantId = btoa(trendingVariantId);
            setadding(true);
            let data = { variantId, quantity: 1, customAttributes: [] };
            await store.addItem(data);
            setadding(false);
          }}
        >
          ADD
        </div>
      </StyledDiv>
    )
}
