import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import store from '../../stores/common-store'
import 'twin.macro'

function RewardMessage() {

    useEffect(() => {
        if (store?.checkout?.lineItems?.length > 0)
            store.cartTopMessage = '$25 Off Prolon + 30 Free Intermittent Fasting Bars'
    }, [store.checkout.lineItems])
    return (
        <>
            {
                store.checkout.lineItems && store.checkout.lineItems.length > 0 ? <div tw="flex justify-center items-center text-sm font-semibold pt-3 pb-5 px-5 text-center" style={{ background: '#f5f7fa' }}>
                    {store.cartTopMessage}
                </div> : null
            }
        </>
    )
}

export default observer(RewardMessage)
