
import React from 'react';
import store from "../../stores/common-store";
import { observer } from "mobx-react";

 
const Header = ({ header, hideCart }) => {
  // You can also pass a callback to the setter
  
  return (
    <div className="drawer__fixed-header">
    <div className="drawer__header">
    <div className="drawer__close">
        <button
          type="button"
          onClick={()=>store.hideCart()} 
          className="icon-fallback-text drawer__close-button js-drawer-close"
        >
          <svg width="25px" height="25px" className="header-close" viewBox="0 0 25 25" version="1.1"><g id="09-Cart" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Cart" transform="translate(-1383.000000, -283.000000)"><g id="Product" transform="translate(1002.000000, 283.000000)"><g id="Group-2-Copy-4" transform="translate(381.000000, 0.000000)"><circle id="Oval" fill="#F6F8F9" cx="12.5" cy="12.5" r="12.5"></circle><g id="icons8-delete_sign" transform="translate(8.000000, 8.000000)" fill="#9CACB1" fill-rule="nonzero"><path d="M1.22735643,0.000115064105 C1.12268296,0.000115064105 1.01789443,0.0399876041 0.938123781,0.119962874 L0.119962874,0.938123781 C-0.0399876246,1.09807424 -0.0399876246,1.35704773 0.119962874,1.51658907 L3.10337402,4.5 L0.119962874,7.48341112 C-0.0399876246,7.64336158 -0.0399876246,7.90233487 0.119962874,8.06187625 L0.938123781,8.88003716 C1.09807424,9.03998761 1.35704773,9.03998761 1.51658907,8.88003716 L4.5,5.89662609 L7.48341112,8.88003716 C7.6429525,9.03998761 7.90233487,9.03998761 8.06187625,8.88003716 L8.88003716,8.06187625 C9.03998761,7.90192579 9.03998761,7.6429525 8.88003716,7.48341112 L5.89662609,4.5 L8.88003716,1.51658907 C9.03998761,1.35704769 9.03998761,1.09766516 8.88003716,0.938123781 L8.06187625,0.119962874 C7.90192579,-0.0399876246 7.6429525,-0.0399876246 7.48341112,0.119962874 L4.5,3.10337402 L1.51658907,0.119962874 C1.43661384,0.0399876041 1.33202989,0.000115064105 1.22735643,0.000115064105 Z" id="Path"></path></g></g></g></g></g></svg>
        </button> 
      </div>
     <div className="drawer__title">{header}</div>
   </div>
  </div> );
};
export default observer(Header);
