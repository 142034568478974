import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import db from '../../stores/common-store'
import SkeltonLoading from "../common/skelton";
import EmptyCart from "./emptyCart";
import Product from "./single/product";
import triggerEvent from "../common/events";

const CartProducts = () => {


  const [cart, setCart] = useState(false);
  // let Cart= db.Checkout; 
  let loadingCart = db.loadingCart;

  useEffect(() => {
    if (db.checkout) {
      setCart(db.checkout);
    }
  }, [db.checkout, db.showCartBolean]);


  let cartProducts = [];
  if (cart && cart.lineItems && cart.lineItems.length > 0) {
    cartProducts = [];

    cart.lineItems.forEach((item, i) => {
      let lastProduct = cart.lineItems.length - 1 == i ? true : false;
      // console.log("compre",lastProduct); 
      cartProducts.push(<Product key={i} item={item} lastProduct={lastProduct} />);
    });
    cartProducts = cartProducts.reverse();
  } else if (loadingCart) {
    cartProducts = <SkeltonLoading />;
  } else {
    cartProducts = <EmptyCart />;
  }

  return (
    < >
      {cartProducts}
    </>
  );
};
export default observer(CartProducts);
