import { observer } from 'mobx-react';
import React, { useState } from 'react'
import store from '../../../stores/common-store'
import axios from 'axios'
import Styled from 'styled-components'
import 'twin.macro'

const Flex = Styled.div`
    width: 60%;
    margin-right: 30px;
    margin-left: auto;
    margin-top: 35px;
    margin-bottom: 50px;

    @media only screen and (max-width:769px) {
        width: 100%;

        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
	}
`;
const InputWrapper = Styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
padding: 5px;
.discount-input{
    width: 68%;
    height: 40px;
    padding: 5px;
    border: 1px solid gray !important;
    text-align: center;
    background: #edf1f2;
    &:focus{
        outline: none;
    }
    &::placeholder{
        color: #202324;
        opacity: .6;
    }
}
.apply-button{
    width: 30%;
    // float: left;
    background: #9ACA38;
    border: none;
    height: 40px;
    color: #fff;
    &:focus{
        outline: none;
    }
}
`;
const DiscountBanner = Styled.div`
    background: #fd6e0d;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    fontSize: 15px;
    fontWeight: 600;
`;
function Discount() {
    const [code, setCode] = useState('')
    const [error, setError] = useState(null)
    const [showBanner, setShowBanner] = useState(false)
    const [loading, setLoading] = useState(false)
    const apply = async (e) => {
        e.preventDefault()
        if (window && localStorage && !store.termsAgreed) {
            alert('You must agree with the healthy start product warning to check out')
            return;
        }
        // console.log('store.checkout', store.checkout)
        setLoading(true)
        try {
            if (!code) {
                setError('Required')
                return;
            }
            if (!store.shopifyCheckoutId) return
            // console.log('checkSubscriptionProduct(): ', checkSubscriptionProduct());
            if (checkSubscriptionProduct()) {
                // console.log('Subscription based.');
                const discount_code = await checkCodeInRecharge(code)
                if (discount_code) {
                    store.checkoutUser(null, code)
                }
            } else {
                // console.log('code', code);
                const valid = await checkCodeValidityInShopify(code)
                if (!valid) {
                    setError('Code is invalid.')
                    setShowBanner(true)
                    return;
                }
                const temporalCheckout = await store.client.checkout.addDiscount(store.shopifyCheckoutId, code)
                // console.log('temporalCheckout: ', temporalCheckout);
                store.setCheckout(temporalCheckout)
                store.checkoutUser()
            }
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoading(false)
            setTimeout(() => {
                setShowBanner(false)
            }, 60000)
        }
    }
    const checkCodeValidityInShopify = async (code) => {
        try {
            // https://prolon-pro.herokuapp.com/stores/lookup
            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                accesstoken: "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",
                shopname: "prolonfast",
                discount: code
            }
            const { data } = await axios({
                method: 'GET',
                headers,
                url: 'https://prolon-pro.herokuapp.com/stores/lookup',
            });
            if (data) {
                return true
            }
        } catch (error) {
            console.log('error: ', error);
            return false
        }
    }

    const checkCodeInRecharge = async (code) => {
        try {
            const { data } = await store.sendGetRequest(`/stores/check-discount-code-in-recharge`, { code }, store.shop)
            // console.log('data: ', data);
            if (data.found) {
                return data.found
            } else {
                setError('Code is invalid.')
                setShowBanner(true)
                return null
            }
        } catch (error) {
            console.log('checkCodeInRecharge error: ', error);
            setShowBanner(true)
        } finally {
            // setTimeout(() => {
            //     setShowBanner(false)
            // }, 5000)
        }
    }
    const checkSubscriptionProduct = () => {
        let isSubscription = false
        for (let item of store.checkout.lineItems) {
            if (!item.customAttributes || item.customAttributes.length == 0) {
                continue;
            }
            for (let attr of item.customAttributes) {
                if (attr.key == 'subscription' && attr.value == 'true') {
                    isSubscription = true
                    break
                }
            }
            if (isSubscription) {
                break;
            }
        }
        return isSubscription
    }

    return (
        <>
            {
                store.checkout?.lineItems && store.checkout.lineItems.length > 0 && <>
                    {
                        showBanner && <DiscountBanner className="animated flash">USE "CART10"AND SAVE 10% OF YOUR ORDER.</DiscountBanner>
                    }
                    <Flex>
                        <small tw="block text-right md:text-right pr-2" >Have a discount code? Enter it here:</small>
                        <InputWrapper>
                            <input className="discount-input" type="text" value={code} onChange={(e) => {
                                setError(null)
                                setCode(e.target.value)
                            }} placeholder="Code ex: SALE10" />
                            <button onClick={apply} className={`apply-button ${loading ? 'btn--loading' : ''}`}>Apply</button>
                        </InputWrapper>
                        {error && <p><small style={{ color: 'red' }}>{error}</small></p>}
                    </Flex>
                </>
            }
        </>
    )
}

export default observer(Discount)
