import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import store from '../../stores/common-store';
import { navigate } from 'gatsby';
import 'twin.macro'

const Footer = () => {
  let Cart = {};
  const [checked, setChecked] = useState(true)
  const CheckoutBtn = useRef(null);

  // You can also pass a callback to the setter
  let total_price = Cart ? Cart.total_price : '000';
  let isSubscriptionProduct = false;
  if (Cart && Cart.items && Cart.items.length > 0) {
    for (let item of Cart.items) {
      if (item.properties) {
        for (const [key, value] of Object.entries(item.properties)) {
          if (
            key.toLowerCase().includes('subscription') ||
            key.toLowerCase().includes('shipping_interval_frequency')
          ) {
            isSubscriptionProduct = true;
            break;
          }
        }
      }
    }
  }

  return (
    <>
      <div
        className="ajaxcart__footer ajaxcart__footer--fixed"
      // style={{ height: 130 }}
      >
        <div tw="flex mb-2 border-b-2 border-red-600 border-solid">
          <input
            tw="border-2! border-orange-600!"
            id="term-checkbox"
            type="checkbox"
            tw="mt-1"
            checked={store.termsAgreed}
            onChange={(e) => store.termsAgreed = e.target.checked}
          />
          <label htmlFor="term-checkbox">
            <small>&nbsp; I AGREE WITH THE <a href="/pages/healthy-start-commitment" tw="underline!">HEALTHY START COMMITMENT</a>, PLEASE PROCEED WITH MY PURCHASE.</small>
          </label>
        </div>
        <p className="cart__terms" style={{ marginTop: 0, fontSize: 12 }}>
          By clicking the checkout button below, I represent I agree to the
          &nbsp;
              <a href="/pages/terms-conditions/" target="_blank" className="terms-link">
            Terms
              </a>
              .
            </p>
        <div className="grid--full">
          <div className="grid__item one-quarter">
            <p className="ajaxcart__subtotal-label">Subtotal</p>
            <p className="ajaxcart__subtotal-price money">
              $ {(store?.checkout?.subtotalPrice)}
            </p>
          </div>
          <div className="grid__item three-quarters">
            {/* {isSubscriptionProduct ? ( */}
            {1 == 2 ? (
              <button
                className="btn--secondary btn--full cart__checkout"
                name="checkout"
                onClick={(e) => {
                  e.preventDefault();
                  window.ProcessSubscriptionCheckout &&
                    window.ProcessSubscriptionCheckout();
                }}
              >
                Checkout
              </button>
            ) :
              (
                <button
                  ref={CheckoutBtn}
                  className="btn--secondary btn--full cart__checkout"
                  name="checkout"
                  onClick={(e) => {
                    e.preventDefault();
                    if (window && localStorage && !store.termsAgreed) {
                      alert('You must agree with the healthy start product warning to check out')
                      return;
                    }
                    //CheckoutBtn.current.className = CheckoutBtn.current.className + " btn--loading";
                    // navigate(store?.checkout?.webUrl);
                    store.checkoutUser(CheckoutBtn);
                    // if (window.OverRideCheckoutLink) {
                    //   e.preventDefault();
                    //   window.OverRideCheckoutLink();
                    // } else {
                    //   location.href == '/checkout';
                    // }
                  }}
                >
                  Checkout
                </button>
              )}
          </div>
        </div>

        <p className="ajaxcart__policies">
          Taxes and shipping calculated at checkout
    </p>
      </div>
    </>
  );
};

export default observer(Footer);
