import React from 'react'
import TestimonialCart from './TestimonialCart'
import TestimonialDetail from './TestimonialDetail'

export default function HelthyCommitment() {
    return (
        <div style={{overflow:'auto', height: '100%', width: '100%'}}>
            <TestimonialCart />
            <TestimonialDetail />
        </div>
    )
}
