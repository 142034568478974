import clsx from "clsx";
import React, { useEffect, useState } from 'react';
import db from "../../stores/common-store";
import axios from "axios";
import { observer } from 'mobx-react';
const Rewards = ({ Cart, processGift, removeProductByLine }) => {
  let [progress, setProgress] = useState(0);
  let [rewardMessage, setRewardMessage] = useState("");
  let {
    emptyMessage,
    value,
    inProgressMessage,
    condtionalBy,
    finalMessage,
    product_variant_id,
    enabled,
  } = db.RewardSection;

  //console.log(`testing `,removeProductByLine); 
  const addRewardValue = (message, v) => {
    return message.replace("_value_", "$" + v.toFixed(2));
  };
  emptyMessage = addRewardValue(emptyMessage, value);
  finalMessage = addRewardValue(finalMessage, value);
  useEffect(() => {

    calcReward(db.checkout);
    // lookForUnwantedFreeProduct();
  }, [db.checkout && db.checkout.lineItems]);

  const lookForUnwantedFreeProduct = () => {
    // console.log(`looking for unwanted product`, Cart);
    if (Cart && Cart.items && Cart.items.length > 0) {
      for (let [index, item] of Cart.items.entries()) {
        if (
          item &&
          item.final_price > 0 &&
          item.variant_id == product_variant_id
        ) {
          console.log(`found unwanted product`, index, value);
          context.actions(context.store).removeProductByLine(index);
        }
      }
    }
  };

  const calcReward = async (Cart) => {
    try {
      if (Cart && Cart.lineItems > 0) {
        let { subtotalPrice } = Cart;
        let CondtionalTotalPrice = 0;
        let GiveGift = false;
        let inProgress = false;
        let rewardValue = value;
        let total_price = subtotalPrice / 100;
        if (condtionalBy && condtionalBy.length > 0) {
          for (let cBy of condtionalBy) {
            if (cBy && cBy.type == "specific_collection") {
              let cartwithCollections = await axios.get("/cart?view=slidecart");
              if (cBy.conditiontype == "amount") {
                if (cartwithCollections && cartwithCollections.data) {
                  let items = JSON.parse(cartwithCollections.data);
                  if (items && items.length > 0) {
                    for (let v of items) {
                      if (
                        v.collections &&
                        v.collections != "" &&
                        v.collections.length > 0
                      ) {
                        for (let col of v.collections) {
                          if (col.handle == cBy.value) {
                            CondtionalTotalPrice += v.line_price;
                          }
                        }
                      }
                    }
                  }
                }
                total_price = CondtionalTotalPrice / 100;
              } else if (cBy.conditiontype == "count") {
                if (cartwithCollections && cartwithCollections.data) {
                  let items = JSON.parse(cartwithCollections.data);
                  if (items && items.length > 0) {
                    for (let v of items) {
                      if (
                        v.collections &&
                        v.collections != "" &&
                        v.collections.length > 0
                      ) {
                        for (let col of v.collections) {
                          if (col.handle == cBy.value) {
                            GiveGift = true;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (cBy && cBy.type == "cart_total") {
              let value = cBy.value;
              rewardValue = value;
              inProgress = true;
              if (total_price >= value) {
                GiveGift = true;
                inProgress = false;
              }
            }
            // {
            //   conditiontype: "exclude",
            //   type: "specific_variants",
            //   value: ["32791770267694","32791770431534"],

            // }
            if (cBy && cBy.type == "specific_variants") {

              if (cBy.conditiontype == 'exclude') {
                let excludedVariants = cBy.value;
                // let onlyExcludedVariantsFound = true;
                let validExcludeCount = 0;
                if (excludedVariants && excludedVariants.length > 0) {
                  for (let exV of excludedVariants) {
                    let item = Cart.items.find(e => {
                      return e.variant_id == exV
                    });
                    if (item) {
                      validExcludeCount++;
                    }


                  }
                  if (validExcludeCount == Cart.items.length) {
                    inProgress = false;
                    GiveGift = false;
                    emptyMessage = null;;
                  }
                }
                //if (Cart && Cart.item_count == 0) {



              }

            }


          }
        }
        if (!GiveGift && !inProgress) {
          setRewardMessage(emptyMessage);
          setProgress(0);
          processGift(false);
          return;
        }
        if (total_price < rewardValue || !GiveGift) {
          var rewardDiference = rewardValue - total_price;
          if (rewardDiference > 0) {
            var diff = (total_price / rewardValue).toFixed(2) * 100;

            setProgress(diff.toFixed(0));
            let rMessage = addRewardValue(inProgressMessage, rewardDiference);
            setRewardMessage(rMessage);
          }
          processGift(false);
        } else if (GiveGift && !inProgress) {
          setRewardMessage(finalMessage);
          setProgress(100);
          processGift(true);
        }
      } else {
        setRewardMessage(emptyMessage);

        setProgress(0);
        if (Cart && Cart.item_count > 0) {
          processGift(false);
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  if (enabled)
    return (
      <div
        className=" "
        className={clsx({
          "ajaxcart-progress": true,
          "ajaxcart-progress--full": progress >= 100,
        })}
      >
        {rewardMessage &&
          <div className="ajaxcart-progress__inner">
            <h5 className="ajaxcart-progress__title">{rewardMessage}</h5>
            <div
              className={clsx({
                "ajaxcart-progress__bar": true,
                hidden: progress == 0,
              })}
            >
              <span
                className="ajaxcart-progress__bar-active"
                style={{ width: `${progress}%` }}
              >
                <span className="ajaxcart-progress__bar-digit">{progress}%</span>
              </span>
            </div>
          </div>}
      </div>
    );
};
export default observer(Rewards); 
