import React from 'react';
import './cart-style.scss';
import clsx from 'clsx';
import Cart from './cart';
import { observer } from 'mobx-react';
 
const SlideCartLoader = () => {
  return <Cart />;
};

export default observer(SlideCartLoader);
