import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import store from '../../../stores/common-store';
import SkeltonCard from '../../common/skeltoncard';
import s from '../scss/product.module.scss';
// import UpsellProduct from "./upsellProduct";
import axios from 'axios';
import 'twin.macro';

const Product = ({
  item
}) => {
  // console.log('item: ', item);
  const [loading, setLoading] = useState(false);

  const rProduct = (product) => {
    doLoading(true);
    store.removeItem(product.id);
  };

  const IncreaseProductQuantity = async (product) => {
    doLoading(true);
    await store.updateItem({ id: product.id, quantity: product.quantity + 1 });
    setLoading(false);
  };
  const DecreaseProductQuantity = async (product) => {
    doLoading(true);
    await store.updateItem({ id: product.id, quantity: product.quantity - 1 });
    setLoading(false);

  };
  const ChangeProductQuantity = async (product, qty) => {
    doLoading(true);
    await store.updateItem({ id: product.id, quantity: qty });
    setLoading(false);
  };

  const doLoading = () => {
    setLoading(true);
    setTimeout((e) => {
      setLoading(false);
    }, 700);
  };

  if (loading) {
    return <SkeltonCard />;
  } else {
    let meta;
    let { variant } = item;
    let originalPrice = parseFloat(variant.price);
    let discountText = null
    if (item.discountAllocations && item.discountAllocations[0] && item.discountAllocations[0].allocatedAmount) {
      const allocated = item.discountAllocations[0].allocatedAmount
      originalPrice = originalPrice - (parseInt(allocated.amount) * item.quantity)
      discountText = item.discountAllocations[0].discountApplication.description
    }
    // console.log('discountText: ', discountText);
    if (item && item.customAttributes) {
      meta = item?.customAttributes?.map(function (attr, index) {
        if (attr.key.startsWith('_') || attr.key.startsWith('free_')) {
          return ``;
        } else {
          return (
            <span tw={"block text-sm text-gray-600"} className={"meta-" + attr.key + " "}>
              {attr.key} : {attr.value}
            </span>
          );
        }
      });
    }
    return (
      <React.Fragment key={item.id}>
        <div tw="flex justify-between p-5" style={{ borderBottom: '1px solid #eeeeee' }}>
          <img
            src={variant.image.src}
            tw="w-16 h-16 object-cover object-left"
          />
          <div tw="ml-5 w-full">
            <div tw="flex justify-between">
              <div tw="font-bold"> {item.title}</div>
              <div tw="bg-gray-200 w-9 h-7 text-center text-base rounded-3xl cursor-pointer" onClick={() => {
                rProduct(item)
              }}>
                x
              </div>
            </div>
            <div tw="text-sm"> {variant.title}</div>
            <div tw="text-sm text-red-600">
              $ {parseFloat(variant.price).toFixed(2)}
            </div>
            {item.selling_plan_allocation && (
              <span
                tw="text-sm"
                className={
                  'subscription-text ' + s['ajaxcart__product-meta-wrap']
                }
              >
                {item.selling_plan_allocation.selling_plan &&
                  item.selling_plan_allocation.selling_plan.name}
              </span>
            )}
            <span tw="text-sm" className={s['ajaxcart__product-meta-wrap']}>
              {meta}
            </span>
            <div tw="flex justify-between items-center">
              <div tw="flex">
                {parseInt(originalPrice) > 0 ? (
                  <>
                    <button
                      type="button"
                      tw="bg-gray-200! w-9 h-7 text-center text-base"
                      aria-label="Reduce item quantity by one"
                      onClick={() => {
                        DecreaseProductQuantity(item);
                      }}
                    >
                      <span
                        className={clsx(s.icon, s['pk-minus'])}
                        aria-hidden="true"
                      ></span>
                      <span className={s['fallback-text']} aria-hidden="true">
                        −
                      </span>
                    </button>
                    <input
                      type="text"
                      name={`updates[${item.id}]`}
                      tw="w-9 h-7 text-center text-base bg-white"
                      value={item.quantity}
                      min="0"
                      onChange={(e) => {
                        ChangeProductQuantity(item, e.target.value);
                      }}
                      aria-label="quantity"
                      pattern="[0-9]*"
                    />
                    <button
                      type="button"
                      tw="bg-gray-200! w-9 h-7 text-center text-base"
                      onClick={() => {
                        IncreaseProductQuantity(item);
                      }}
                      aria-label="Increase item quantity by one"
                    >
                      <span
                        className={clsx(s['icon'], s['pk-plus'])}
                        aria-hidden="true"
                      ></span>
                      <span className={s['fallback-text']} aria-hidden="true">
                        +
                      </span>
                    </button>
                  </>
                ) : (
                    <input
                      type="text"
                      name={`updates[${item.id}]`}
                      tw="w-9 h-7 text-center text-base"
                      value={item.quantity}
                      min="0"
                      readOnly
                      aria-label="quantity"
                      pattern="[0-9]*"
                    />
                  )}
              </div>
              <div className="price" tw="text-right block">
                {variant.compareAtPrice !== originalPrice ? (
                  <>
                    {parseFloat(variant.compareAtPrice) >
                      parseFloat(originalPrice) ? (
                        <span tw="text-red-600 text-xs line-through block">
                          {(variant.compareAtPrice * item.quantity).toFixed(2)}
                        </span>
                      ) : (
                        <span tw="text-red-600 text-xs line-through block">
                          $ {(originalPrice * item.quantity).toFixed(2)}
                        </span>
                      )}

                    <span data-price={originalPrice} tw="text-red-600 text-lg block">
                      $ {(originalPrice * item.quantity).toFixed(2)}
                    </span>
                  </>
                ) : (
                    <>
                      {variant.compareAtPrice > variant.price ? (
                        <span tw="text-red-600 text-xs line-through block">
                          {(variant.compareAtPrice * item.quantity).toFixed(2)}
                        </span>
                      ) : (
                          ''
                        )}

                      <span data-price={originalPrice} tw="text-red-600 text-lg block">
                        $ {(originalPrice * item.quantity).toFixed(2)}
                      </span>
                    </>
                  )}
              </div>
            </div>
            {discountText && <small style={{ color: 'red' }}>{discountText}</small>}
          </div>
        </div>
      </React.Fragment>
    );
  }
};
export default observer(Product);
