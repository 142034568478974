import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import store from "../../../stores/common-store"
import clsx from "clsx";
import s from "../scss/upsell.modular.scss";
import SkeltonLoading from '../../common/skelton';
import 'twin.macro'

function Offer() {

    const [offerProductsToShow, setOfferProductsToShow] = useState([])
    const [currentVariant, setCurrentVariant] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // console.log('Checking offers...')
        checkOffersToShow(store.checkout.lineItems)
    }, [store.checkout.lineItems])
    // useEffect(() => {
    //     console.log('Checking offers...')
    //     checkOffersToShow(store.checkout.lineItems)
    // }, [store.showCartBolean])
    const checkOffersToShow = async (items) => {
        try {
            if (!items) {
                return;
            }
            // setLoading(true);
            let offerProducts = []
            for (let item of items) {
                let attribute = store.findCustomAttributeByKey('_offer', item)
                if (!attribute) continue
                let offer = getOfferDetails(attribute.value)
                if (!offer) continue
                let offerProduct = await store.getProductByHandle(offer.product)
                if (!offerProduct) continue
                let aleadyAdded = checkIfAlreadyAdded(offerProduct)
                if (aleadyAdded) continue
                // console.log('offerProduct: ', offerProduct);
                let find = offerProducts.find(p => p.id == offerProduct.id)
                if (find) continue
                offerProducts.push(offerProduct)
            }
            setOfferProductsToShow(offerProducts)
            if (offerProducts.length > 0) {
                // console.log('offerProducts: ', offerProducts);
                setCurrentVariant(offerProducts[0].variants[0])
            }
        } catch (error) {
            console.log('checkOffersToShow error: ', error);
        } finally {
            setLoading(false)
        }
    }
    const getOfferDetails = (offer) => {
        let rec = store.Offers.find(o => o.key == offer)
        return rec
    }
    const checkIfAlreadyAdded = (product) => {
        for (let variant of product.variants) {
            let item = store.checkout.lineItems.find(item => item.variant.id == variant.id)
            if (item) return item
        }
        return null
    }
    return (
        <React.Fragment>
            {
                loading ? <SkeltonLoading cardSize="1" /> : <>
                    {
                        (offerProductsToShow && offerProductsToShow.length > 0 && currentVariant) &&
                        <section style={{ border: "2px dashed #9aca37" }} tw="mx-4">
                            <div tw="border divide-dotted">
                                <span className="topbar" tw="block text-center text-white p-2 mb-0 font-semibold">SPECIAL OFFER — BUY 1 GET 1 FOR $20 OFF</span>
                            </div>
                            {
                                offerProductsToShow.map((product, i) => {
                                    if (i == 0) {
                                        return <div tw="flex px-5 py-2 bg-gray-100 items-center justify-between" key={i}>
                                            {/* <img src="https://cdn.shopify.com/s/files/1/0126/2921/3243/products/ProLonSubscription.png?v=1600322185" tw="w-16 h-16 object-cover object-left" /> */}
                                            <div tw="flex">
                                                <img src={currentVariant.image.src} tw="w-20 h-20 object-cover object-left mr-2 border! border-solid! border-gray-300! rounded-md!" />
                                                <div>
                                                    <h1 tw="font-semibold text-sm">{product.title}</h1>
                                                    <select tw="font-semibold bg-gray-100 focus:outline-none" onChange={(e) => {
                                                        let id = e.target.value;
                                                        let variant = product.variants.filter(
                                                            (e) => {
                                                                return e.id == id;
                                                            }
                                                        );
                                                        variant = variant ? variant[0] : null;
                                                        setCurrentVariant(variant);
                                                    }} >
                                                        {product.variants.map((variant, index) => {
                                                            return (
                                                                <option key={index} value={variant.id}>
                                                                    {variant.title}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div tw="flex text-xs">
                                                        <p tw="text-red-500">$ {currentVariant.price}</p>
                                                        <p tw="pl-3 text-gray-500 line-through">$ {currentVariant.compareAtPrice}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <button tw="bg-black! text-white! h-7! w-16! text-xs! font-bold! mr-4"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    // let p = {};

                                                    // let qty = 1;
                                                    // if (Cart && Cart.items && Cart.items.length > 0) {
                                                    //     Cart.items.forEach(e => {
                                                    //         if (e.variant_id == currentVariant.id) {
                                                    //             qty += e.quantity;
                                                    //         }
                                                    //     })
                                                    // }

                                                    // p[currentVariant.id] = qty;
                                                    setLoading(true);
                                                    store.addItem({
                                                        variantId: currentVariant.id,
                                                        quantity: 1,
                                                        customAttributes: [
                                                            {
                                                                key: '_from_offer',
                                                                value: 'true'
                                                            }
                                                        ]
                                                    })
                                                    setOfferProductsToShow([])
                                                    setTimeout(function () {
                                                        setLoading(false);
                                                    }, 700);
                                                }}>ADD</button>
                                        </div>
                                    }
                                })
                            }
                        </section>
                    }
                    {/* {
                        offerProductsToShow.map((product, i) => {
                            return <div className={clsx(s["custom-upsell-product"])} key={i}>
                                <div className={clsx(s["product-image-wrapper"])}>

                                    <img
                                    src={loadImage(currentVariant.image_id)}
                                    className={clsx(s["product-image"], s["medium"])}
                                />

                                </div>
                                <div className={clsx(s["details"])}>
                                    <div className={clsx(s["product-title-wrapper"])}>
                                        <span
                                            className={clsx(s["product-title"])}
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {product.title}
                                        </span>
                                        <br />
                                        <div
                                            className={s["ajaxcart_product-description"]}
                                            style={{ fontSize: '14px', color: "black" }}
                                        >
                                            {product.variants &&
                                                product.variants.length > 1 && (
                                                    <select
                                                        className="product-variants"
                                                        onChange={(e) => {
                                                            let id = e.target.value;
                                                            let variant = product.variants.filter(
                                                                (e) => {
                                                                    return e.id == id;
                                                                }
                                                            );
                                                            variant = variant ? variant[0] : null;
                                                            setCurrentVariant(variant);
                                                        }}
                                                    >
                                                        {product.variants.map((variant) => {
                                                            return (
                                                                <option value={variant.id}>
                                                                    {variant.title}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                )}
                                        </div>
                                        <span className={clsx(s["product-price-wrapper"], "money")}>
                                            {currentVariant && currentVariant.price ? (
                                                <React.Fragment>
                                                    <span style={{ color: "red" }}>
                                                        {" "}
                                      $ {currentVariant.price.replace(".00", "")}
                                                    </span>
                                                    <s style={{ marginLeft: "10px", color: "grey" }}>
                                                        ${currentVariant.compare_at_price.replace(".00", "")}
                                                    </s>
                                                </React.Fragment>
                                            ) : null}
                                        </span>
                                    </div>
                                </div>
                                <div className={clsx(s["variants"])}>
                                    <button
                                        name="add"
                                        className={clsx(s["btn"], s["product-price"])}
                                        ref={addtoCartButtn}
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            // let p = {};

                                            // let qty = 1;
                                            // if (Cart && Cart.items && Cart.items.length > 0) {
                                            //     Cart.items.forEach(e => {
                                            //         if (e.variant_id == currentVariant.id) {
                                            //             qty += e.quantity;
                                            //         }
                                            //     })
                                            // }

                                            // p[currentVariant.id] = qty;
                                            setLoading(true);
                                            store.addItem({
                                                variantId: currentVariant.id,
                                                quantity: 1,
                                                customAttributes: [
                                                    {
                                                        key: 'from_offer',
                                                        value: 'true'
                                                    }
                                                ]
                                            })
                                            setTimeout(function () {
                                                setLoading(false);
                                            }, 700);
                                        }}
                                    >
                                        Add
                            </button>
                                </div>
                            </div>
                        })
                    } */}
                </>
            }
        </React.Fragment >
    )
}

export default observer(Offer)
