import React, { useEffect } from 'react';
import clsx from "clsx";
import Header from "./sections/header";
import { observer } from "mobx-react";
import store from "../stores/common-store";
import Footer from './sections/footer';
import Products from './sections/products';
import Reward from './sections/Reward';
import CrossSell from './sections/CrossSell';
import Offer from './sections/single/Offer';
import Discount from './sections/single/Discount';
import AmountSaved from './sections/single/AmountSaved';
import RewardMessage from './sections/RewardMessage';
import HelthyCommitment from '../theme/custom-sections/HomePage/CartSection/HelthyCommitment';
import TrendingNow from '../theme/custom-sections/HomePage/CartSection/TrendingNow';

const Cart = () => {

  useEffect(() => {
    window.STORE = store;
    store.checkCartExistance();
  }, []);

  let isSubscriptionProduct = false;

  return (
    <div className="ss-shopify_cart ">
      {store.showCartBolean && (
        <>
        <div
          className="ss-background-wrapper pointer"
          onClick={() => store.hideCart()}
        ></div>
        </>
      )}

      <div className={`side-healthy-commitment  ${store.showCartBolean && 'active'}`}>
        <HelthyCommitment />
      </div>
      <div
        className={clsx({
          drawer: true,
          "drawer--right": store.showCartBolean,
          "drawer--has-fixed-footer": true,
        })}
        tabindex="-1"
      >
        <Header header={"Shopping Cart"} />
        <div className="drawer__inner">
          <div id="CartContainer" className="drawer__cart">
            <form
              className="cart ajaxcart pf-form-processed"
            >
              <div
                className={clsx({
                  ajaxcart__inner: true,
                  "ajaxcart__inner--has-fixed-footer": true,
                  "has-subscription-product": isSubscriptionProduct,
                  "no-sub-product": !isSubscriptionProduct,
                })}
                style={{ bottom: 130 }}
              >
                {/* <Reward /> */}
                <RewardMessage />
                <div className="ajaxcart__product shopify-cart-product-overflow-wrapper">
                  {/* <Addon /> */}
                  {/* <CrossSell /> */}
                  <Offer products={store.offerProductsToShow} />
                  {/* <Offer /> */}
                  <Products />
                  <TrendingNow />
                  <AmountSaved />
                  <Discount />
                  <div className={`side-healthy-commitment-mobile mb-8`} >
                    <HelthyCommitment />
                  </div>

                </div>
              </div>

              <Footer />
            </form>
          </div>
        </div>

      </div>
    </div>
  );
}

export default observer(Cart);
