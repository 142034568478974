import React from 'react';
import s from './skelton.module.scss';
 
const SkeltonCard = () => {
  // You can also pass a callback to the setter
  return (   
    <div className={s["PolarisCard"]}>
      <div className={s["PolarisCard__Section"]} >
        <div className={s["PolarisSkeletonBodyText__SkeletonBodyTextContainer" ]}>
          <div className={s["PolarisSkeletonBodyText"]}></div>
          <div className={s["PolarisSkeletonBodyText"]}></div>
          <div className={s["PolarisSkeletonBodyText"]}></div>
          </div>   
      </div> 
    </div>
  );
};
export default SkeltonCard; 
