import React, { useEffect, useState } from 'react';
import 'twin.macro';
import { observer } from 'mobx-react';
import store from '../../../../stores/common-store';
import styled from 'styled-components';
import 'lodash';
import TrendingProduct from './TrendingProduct';

const StyledDiv = styled.div`
  .cart_image {
  }
  .heading {
    align-self: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 0.8rem;
    line-height: 1.15rem;
    margin: 0px 0px 0.575rem;
    color: rgb(113, 113, 113);
  }
  .add_button {
    background: rgba(253, 109, 15, 0.68);
    border: 1px solid black;
    color: black;
    font-size: 12px;
    line-height: 1.42;
    font-weight: 900;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
  }
  .price {
    font-size: 0.875rem;
  }
  .price_off {
    color: red;
    font-size: 10px;
    line-height: 2;
    padding-left: 5px;
  }
`;
function TrendingNow() {
    const trendingProducts = [
        {
            variantId: 'gid://shopify/ProductVariant/32858815168558',
            img_src:
              'https://cdn.shopify.com/s/files/1/0126/2921/3243/products/SingleBoxProLon_300x.jpg?v=1612736696',
            heading: 'ProLon Original',
            price: '$169.00',
            priceOff: '($30 off)',
          },
          {
            variantId: 'gid://shopify/ProductVariant/32858819756078',
            img_src:
              'https://cdn.shopify.com/s/files/1/0126/2921/3243/products/FastBar-VarietyPack_720x.png?v=1614016981',
            heading: 'Fast Bar 30ct Variety Pack',
            price: '$35.00',
            priceOff: '($45 off)',
          },
    ]
  const [products, setProducts] = useState([
    {
      variantId: 'gid://shopify/ProductVariant/32858815168558',
      img_src:
        'https://cdn.shopify.com/s/files/1/0126/2921/3243/products/SingleBoxProLon_300x.jpg?v=1612736696',
      heading: 'ProLon Original',
      price: '$169.00',
      priceOff: '($30 off)',
    },
    {
      variantId: 'gid://shopify/ProductVariant/32858819756078',
      img_src:
        'https://cdn.shopify.com/s/files/1/0126/2921/3243/products/FastBar-VarietyPack_720x.png?v=1614016981',
      heading: 'Fast Bar 30ct Variety Pack',
      price: '$35.00',
      priceOff: '($45 off)',
    },
  ]);

  const [trending, settrending] = useState(false);


  useEffect(() => {

    let allAddedIds = store?.checkout?.lineItems?.map((item) => {
      return atob(item.variant.id);
    });

    let filteredProducts = [];

    trendingProducts.forEach((trending) => {
      if (!allAddedIds?.includes(trending.variantId)) {
        filteredProducts.push(trending);
      }
    });
    setProducts(filteredProducts);
  }, [store?.checkout?.lineItems]);

  useEffect(() => {
    let newProducts = products.map((trending) => {
      return (
          <TrendingProduct
            img_src={trending.img_src}
            heading={trending.heading}
            price={trending.price} 
            priceOff={trending.priceOff} 
            trendingVariantId={trending.variantId} />
      );
    });
    settrending(newProducts);
  }, [products]);


//   let newArray = _.filter(trendingProducts, ({variantId}) => {
//     return _.findIndex(allAddedIds, ({variantId}) => { return (variantId == variantId) >= 0});
// })
// console.log('newArray', newArray)

  return (
    <>
    {store?.checkout?.lineItems?.length> 0 && trending.length > 0 && <StyledDiv tw="pb-8" style={{ background: '#F8F8F8' }}>
      <p tw="text-center p-4">Trending Now</p>
       <div tw="flex flex-row">{trending}</div>
    </StyledDiv>
    }
    </>
  );
}

export default observer(TrendingNow);
