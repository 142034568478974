import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import store from '../../../stores/common-store'

function AmountSaved() {
    const [amount, setAmount] = useState(0)
    useEffect(() => {
        calculateSavedAmount()
    }, [store.checkout.lineItems])
    const calculateSavedAmount = () => {
        let totalCurrent = 0
        let totalActual = 0
        if (store.checkout && store.checkout.lineItems && store.checkout.lineItems.length > 0) {
            for (let item of store.checkout.lineItems) {
                totalActual += parseFloat(item.variant.compareAtPrice)
                totalCurrent += parseFloat(item.variant.price)
            }
            setAmount(totalActual - totalCurrent)
        }
    }

    return (
        <>
            {
                store.checkout?.lineItems && store.checkout.lineItems.length > 0 && <>
                    {
                        amount > 0 && <p style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'red',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            marginTop:'20px'
                        }}>Congrats! 🎉 You have saved ${amount} Today.</p>
                    }
                </>
            }
        </>
    )
}

export default observer(AmountSaved)
