import React from 'react';
import SkeltonCard from './skeltoncard';

const SkeltonLoading = ({ cardSize = 2 }) => {
  // You can also pass a callback to the setter

  let Card = [];
  for (let i = 0; i < cardSize; i++) {
    Card.push(<SkeltonCard key={i} />);
  }


  return (
    <div>

      {Card}

    </div>
  );
};
export default SkeltonLoading;
